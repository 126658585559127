
.high-search-page {
}
.back_box {
    width: 1200px;
    margin: 0 auto;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
}
.cont {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.braed {
    margin: 15px 0;
}
.content {
    width: 700px;
    padding-left: 50px;
}
.line {
    text-align: center;
}
.red.el-button {
    background: #BD1A2D;
    border-color: #BD1A2D;
}
.suggestList {
    position: absolute;
    top: 40px;
    left: 0;
    padding: 3px 5px;
    background: #fff;
    width: 200px;
    max-height: 220px;
    overflow: hidden;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 10;
}
.suggestList li {
    line-height: 30px;
    color: #666;
    cursor: pointer;
}
.suggestList li:hover,
.suggestList li.on {
    background: #BD1A2D;
    color: #fff;
}
>>> .el-range-editor.is-active,
.el-range-editor.is-active:hover {
    border-color: #4db6be;
}
>>> .el-date-table td.end-date span,
>>> .el-date-table td.start-date span {
    background-color: #4db6be;
}
>>> .el-checkbox__input.is-checked .el-checkbox__inner,
>>> .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #4db6be;
    border-color: #4db6be;
}
>>> .el-checkbox__input.is-checked + .el-checkbox__label,
>>> .el-date-table td.today span {
    color: #4db6be;
}
